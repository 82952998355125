<template>
  <div class="home">
    <div
      :class="['banner-bg', slide == 0
        ? 'banner-pacientes'
        : slide == 1
          ? 'banner-profesionales'
          : 'banner-directorio'
      ]"
    >
      <div class="login-bar">
        <p class="texto-paciente">
          Reserva tu cita
        </p>
        <p class="texto-doctor">
          Consulta tus recipes
        </p>
        <a
          href="https://paciente.amii.app"
        >
          <mdb-btn
            color="primary-base"
            size="sm"
          >
            <span class="font-weight-bold">
              Acceder
            </span>
          </mdb-btn>
        </a>
      </div>
      <!-- SLIDER PRINCIPAL -->
      <div class="banner">
        <p
          class="col-12 text-decoration-primary banner-header"
        >
          AMII es
        </p>
        <div
          key="right-corner"
          class="corner col-auto col-md-1 z-1 order-1 order-md-0"
        >
          <div
            v-if="slide > 0"
            @click="slideInt = 1; slideChange(false)"
          >
            <font-awesome-icon icon="chevron-left" />
          </div>
        </div>
        <TransitionGroup
          class="col-12 col-md-9 col-lg-10
            transition-group align-items-start"
          :enter-active-class="
            slideDirection == 'right' ? 'right-enter' : 'left-enter'"
          :leave-active-class="
            slideDirection == 'right' ? 'right-leave' : 'left-leave'"
          :duration="500"
          mode="out-in"
        >
          <!-- Pacientes -->
          <div
            v-if="slide == 0"
            key="pacientes"
            class="section-pacientes order-0 col-12"
          >
            <div>
              <p class="text-decoration-primary font-weight-bold">
                Una aplicación para
              </p>
              <p class="text-decoration-secondary text-uppercase">
                Pacientes
              </p>
              <div class="d-flex flex-wrap banner-slide-text">
                <p
                  key="gestiona"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activePatientText.includes('gestiona')}"
                >
                  <span>
                    Gestiona
                  </span>
                </p>
                <p
                  key="citas"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activePatientText.includes('citas')}"
                >
                  <span>
                    citas,
                  </span>
                </p>
                <p
                  key="tratamientos"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activePatientText.includes('tratamientos')}"
                >
                  <span>
                    tratamientos,
                  </span>
                </p>
                <p
                  key="recipes"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activePatientText.includes('recipes')}"
                >
                  <span>
                    recipes,
                  </span>
                </p>
                <p
                  key="otros"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide"
                  :class="{'appear':
                    activePatientText.includes('otros')}"
                >
                  <span>
                    otros...
                  </span>
                </p>
              </div>
              <div
                class="d-flex flex-column
                align-items-center justify-content-between
                buttons-responsive-bottom"
              >
                <router-link
                  :to="{name: 'soyMedico'}"
                  class="col-auto px-0"
                >
                  <mdb-btn
                    color="primary-base"
                    class="px-2 px-md-4 register-btn"
                    rounded
                  >
                    Registrarme como paciente
                  </mdb-btn>
                </router-link>
                o
                <router-link
                  :to="{name: 'soyMedico'}"
                  class="col-auto px-0 warning-text
                    text-center bg-lighter-base py-2 px-3 rounded-xl"
                >
                  Registrarme como profesional
                  <span class="d-block d-sm-inline">
                    en salud
                    <font-awesome-icon
                      icon="angle-right"
                    />
                  </span>
                </router-link>
              </div>
            </div>
          </div>
          <!-- profesionales -->
          <div
            v-if="slide == 1"
            key="profesionales"
            class="section-profesionales col-12"
          >
            <div>
              <p class="text-decoration-primary font-weight-bold">
                Una aplicación para
              </p>
              <p class="text-decoration-secondary text-uppercase">
                profesionales
              </p>
              <p class="text-decoration-primary font-weight-bold">
                de la salud
              </p>
              <div class="d-flex flex-wrap banner-slide-text">
                <p
                  key="médicos"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activeProfesionalText.includes('médicos')}"
                >
                  <span>
                    Médicos,
                  </span>
                </p>
                <p
                  key="odontólogos"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activeProfesionalText.includes('odontólogos')}"
                >
                  <span>
                    Odontólogos,
                  </span>
                </p>
                <p
                  key="psicólogos"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activeProfesionalText.includes('psicólogos')}"
                >
                  <span>
                    Psicólogos,
                  </span>
                </p>
                <p
                  key="otros"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activeProfesionalText.includes('otros')}"
                >
                  <span>
                    otros...
                  </span>
                </p>
              </div>
              <div
                class="d-flex flex-column
                align-items-center justify-content-between
                buttons-responsive-bottom"
              >
                <router-link
                  :to="{name: 'soyMedico'}"
                  class="col-auto px-0"
                >
                  <mdb-btn
                    color="primary-base"
                    class="px-2 px-md-4 register-btn"
                    rounded
                  >
                    Registrarme como médico
                  </mdb-btn>
                </router-link>
                o
                <a
                  href="#reserva-tu-cita"
                  class="col-auto px-0
                    warning-text bg-lighter-base p-2 rounded-xl"
                >
                  ¿Estás buscando un médico?
                  <font-awesome-icon
                    icon="angle-right"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- Directorio -->
          <div
            v-if="slide == 2"
            key="directorio"
            class="section-directorio
              col-12 order-1 order-lg-0"
          >
            <div>
              <p class="text-decoration-secondary text-uppercase">
                Directorio médico
              </p>
              <p class="text-decoration-primary font-weight-bold">
                encuentra por
              </p>
              <div class="d-flex flex-wrap banner-slide-text">
                <p
                  key="nombre"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear': activeDirectoryText.includes('nombre')}"
                >
                  <span>
                    nombre,
                  </span>
                </p>
                <p
                  key="especialidad"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activeDirectoryText.includes('especialidad')}"
                >
                  <span>
                    especialidad,
                  </span>
                </p>
                <p
                  key="ubicacion"
                  class="text-primary text-uppercase
                    font-weight-bold f-banner-slide mr-2"
                  :class="{'appear':
                    activeDirectoryText.includes('ubicacion')}"
                >
                  <span>
                    ubicación.
                  </span>
                </p>
              </div>
              <div class="buttons-responsive-bottom">
                <a
                  href="#reserva-tu-cita"
                  class="col-auto px-0"
                >
                  <mdb-btn
                    color="primary-base"
                    class="px-2 px-md-4 register-btn"
                    rounded
                  >
                    Ir al directorio
                  </mdb-btn>
                </a>
              </div>
            </div>
          </div>
        </TransitionGroup>
        <div
          key="left-corner"
          class="corner col-auto col-md-1 order-2 p-0"
        >
          <div
            v-if="slide < 2"
            @click="slideInt = 1; slideChange(true)"
          >
            <font-awesome-icon icon="chevron-right" />
          </div>
        </div>
      </div>
    </div>
    <!-- Directorio Medico -->
    <div
      class="w-100 bg-primary-dark-base p-0 col-12"
    >
      <div
        id="reserva-tu-cita"
        class="w-75 mx-auto py-5 w-xl-75"
      >
        <div
          id="reserva-tu-cita-form"
          class="pb-2 card my-3"
        >
          <h3 class="text-center mt-3">
            Directorio médico
          </h3>
          <h5 class="text-center mt-3 mb-4">
            Buscar por nombre, especialidad o ambas
          </h5>
          <div class="row px-3 align-items-center">
            <div class="col-12 col-md">
              <mdb-input
                v-model="search.name"
                label="Nombre del Médico"
                class="my-2"
                outline
              />
            </div>
            <div class="col-12 col-md">
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="searchSpeciality"
                  v-model="search.speciality"
                  class="custom-select"
                  @change="resetPagination"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="speciality in tags"
                    :key="speciality.name"
                    :value="speciality.name"
                  >
                    {{ speciality.name }}
                  </option>
                </select>
                <label
                  for="searchSpeciality"
                  :class="search.speciality && search.speciality.length ?
                    'label-active' : 'label-inactive'"
                >
                  Especialidad
                </label>
              </div>
            </div>
            <div
              v-if="search.speciality"
              class="col-12 col-md-auto"
            >
              <mdb-btn
                type="button"
                class="p-2 z-depth-0 w-100 mx-0"
                icon="broom"
                flat
                dark-waves
                @click="search.speciality = ''"
              >
                Limpiar
              </mdb-btn>
            </div>
          </div>
          <div
            v-if="filterSearchBy"
            class="row justify-content-center mx-0"
          >
            <div class="col-12 col-sm-6 col-xl-3">
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="searchCountry"
                  v-model="search.country"
                  class="custom-select"
                  required
                  @change="
                    search.state = '';
                    resetPagination();
                  "
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="country in countries"
                    :key="country.id"
                    :value="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <label
                  for="searchCountry"
                  :class="
                    search.country && search.country.length
                      ? 'label-active'
                      : 'label-inactive'
                  "
                >
                  País
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-3">
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="searchState"
                  v-model="search.state"
                  :class="[
                    'custom-select',
                    {
                      'no-background': $apolloData.queries.states.loading,
                    },
                  ]"
                  required
                  @change="
                    search.city = '';
                    resetPagination();
                  "
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                  >
                    {{ state.name }}
                  </option>
                  <option
                    v-if="states === undefined"
                    disabled
                  >
                    Debes seleccionar un país primero.
                  </option>
                </select>
                <label
                  for="searchState"
                  :class="
                    search.state && search.state.length
                      ? 'label-active'
                      : 'label-inactive'
                  "
                >
                  Estado
                </label>
                <span
                  v-if="$apolloData.queries.states.loading"
                  class="form-loader"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="fa-spin"
                  />
                </span>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-3">
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="searchCity"
                  v-model="search.city"
                  :class="[
                    'custom-select',
                    {
                      'no-background': $apolloData.queries.cities.loading,
                    },
                  ]"
                  required
                  @change="resetPagination"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                  >
                    {{ city.name }}
                  </option>
                  <option
                    v-if="cities === undefined"
                    disabled
                  >
                    Debes seleccionar un país y un estado primero.
                  </option>
                </select>
                <label
                  for="searchCity"
                  :class="
                    search.city && search.city.length
                      ? 'label-active'
                      : 'label-inactive'
                  "
                >
                  Ciudad
                </label>
                <span
                  v-if="$apolloData.queries.cities.loading"
                  class="form-loader"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="fa-spin"
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="!search.name || !search.country || !search.state ||
              !search.city || !search.speciality"
            class="col-auto my-3"
          >
            <mdb-alert
              :class="[
                'mb-0 user-select-none animated ' +
                  'alert-warning-custom',
                { pulse: showAlertSearchBy },
              ]"
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation-triangle']"
                size="lg"
                class="d-inline-block w-27px"
              />
              {{ searchByName }} para buscar
            </mdb-alert>
          </div>
          <mdb-btn
            class="col-auto mx-auto"
            flat
            dark-waves
            :active="filterSearchBy"
            @click="
              filterSearchBy = !filterSearchBy;
              checkFilter = filterSearchBy;
            "
          >
            <font-awesome-icon
              :icon="
                filterSearchBy
                  ? ['fas', 'check-square']
                  : ['far', 'square']
              "
              :class="['mr-1', { 'text-success': filterSearchBy }]"
              size="lg"
            />
            Conozco la ubicación
          </mdb-btn>
          <div
            v-if="!skipDoctors"
            :class="['mt-3', { 'h-reduced': appointment.date }]"
          >
            <p
              v-if="$apolloData.queries.doctors.loading"
              class="text-center text-uppercase user-select-none"
            >
              <font-awesome-icon
                icon="circle-notch"
                size="lg"
                class="d-inline-block fa-spin"
              />
              Cargando
            </p>
            <div
              v-if="doctors"
              class="d-flex flex-wrap gap-2 "
            >
              <TarjetaResultadoDoctor
                v-for="doc in doctors"
                :key="doc.id"
                ref="TarjetaResultadoDoctor"
                :doc="doc"
                class="col-12 col-md-6 col-lg-4 px-2"
                @modal-ver-detalles="detailsDoctor"
              >
                <template #botones>
                  <div
                    :class="[
                      'd-flex mx-n1',
                      doc.profileUrl
                        ? 'justify-content-between'
                        : 'justify-content-end',
                    ]"
                  >
                    <a
                      v-if="doc.profileUrl"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      :href="`/_/${doc.profileUrl}`"
                      @click.stop=""
                    >
                      <mdb-btn
                        type="button"
                        class="p-2 z-depth-0"
                        size="sm"
                        icon="user-circle"
                        icon-class="fa-lg"
                        outline="primary-base"
                        rounded
                      >
                        Perfil
                      </mdb-btn>
                    </a>
                    <mdb-btn
                      type="button"
                      class="py-2 px-3 font-weight-bold"
                      color="success-base"
                      size="sm"
                      icon="calendar-alt"
                      rounded
                    >
                      Agendar
                    </mdb-btn>
                  </div>
                </template>
              </TarjetaResultadoDoctor>
            </div>
            <p
              v-else
              class="text-center"
            >
              No se han encontrado médicos
            </p>
          </div>
          <div
            v-if="doctors.length == pageSize * page"
            class="col-12 d-flex justify-content-center m-0 mt-3"
          >
            <mdb-btn
              type="button"
              color="primary-base"
              @click="showMoreSearchDoctors"
            >
              Ver Más
              <font-awesome-icon
                icon="eye"
                class="ml-1"
              />
            </mdb-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- Beneficios de pacientes -->
    <section class="beneficios-bg d-flex d-lg-block">
      <div
        class="col-12 col-md-auto
          d-flex flex-column align-items-center
          px-3 py-5 w-xl-75 mx-lg-auto"
      >
        <div class="text-center">
          <h3
            class="font-weight-bold text-warning mt-3"
          >
            Como PACIENTES
          </h3>
          <h5 class="mt-3 mb-4">
            Estos son tus beneficios
          </h5>
        </div>
        <ul>
          <li>
            Cuentas con una
            App para solicitar nuevas citas
            <span class="font-weight-bold">fácil y rápido.</span>
          </li>
          <li>
            Revisa tus récipes y mira las citas por
            venir,
            <span class="font-weight-bold">tuyas o de tus hijos.</span>
          </li>
          <li>
            Agrega tratamientos para
            <span class="font-weight-bold">recibir alertas</span>
            en las horas que te toque.
          </li>
          <li>
            Solicita o
            <span class="font-weight-bold">concede acceso a tus AMIIgos</span>
            para que compartan
            <span class="d-block">
              notificaciones, ideal cuando hay responsabilidad compartida.
            </span>
          </li>
        </ul>
        <a
          class="buttons mt-3"
          href="http://paciente.amii.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <mdb-btn
            color="primary-base"
            class="register-btn"
            rounded
          >
            Registrarme como Paciente
          </mdb-btn>
        </a>
      </div>
    </section>
    <div
      id="text-soy-medico"
      class="bg-primary-dark-base p-3 text-center"
    >
      <h3 class="text-white mb-2 font-weight-bold">
        ¿Eres médico?
        <p>
          ¿o profesional de la salud?
        </p>
      </h3>
      <p
        class="text-white"
        style="font-size:1.3rem"
      >
        Ofrece tu servicio en nuestro directorio
        y recibe solicitudes de citas
        <span class="font-weight-bold">¡Es Gratis!</span>
      </p>
      <img
        v-lazy="'/img/soy-medico/amii-logo-mediano-blanco.svg'"
        role="link"
        alt="AMII icono enlace"
        height="auto"
        width="100px"
      >
      <p
        class="text-white"
        style="font-size:1.3rem"
      >
        AMII te
        <span class="font-weight-bold">facilita</span>
        el registro de historias clínicas, recipes,
        informes, pagos, gestión de citas y mucho más.
      </p>
      <p
        class="text-white"
        style="font-size:1.3rem"
      >
        Delega trabajo a tu recepcionista con el
        <span class="font-weight-bold">módulo para Asistentes</span>,
        sin comprometer la privacidad de tus
        pacientes.
      </p>
      <p
        class="text-white"
        style="font-size:1.3rem"
      >
        Usa nuestras herramientas
        tecnológicas y no te quedes atrás
      </p>
      <div class="w-100 text-center">
        <mdb-btn
          color="success-base"
          @click="$router.push('/soy-medico');"
        >
          Conoce más
        </mdb-btn>
      </div>
    </div>
    <div class="bg-lighter-base p-3 d-none">
      <h3 class="text-center">
        Artículos recientes
      </h3>
      <ApolloQuery
        :query="gqlRecentArticles"
        :variables="{first: 3}"
      >
        <template slot-scope="{result:{loading, error, data}}">
          <!-- Loading -->
          <div
            v-if="loading"
            class="loading apollo text-center lead blue-grey-text py-5"
          >
            Cargando...
          </div>

          <!-- Error -->
          <div
            v-else-if="error"
            class="error apollo text-center lead blue-grey-text py-5"
          >
            Los artículos no están disponibles
          </div>

          <!-- Result -->
          <div
            v-else-if="data && data.articles && data.articles.length"
            class="result apollo"
          >
            <div class="d-flex j-center">
              <div
                class="row pt-3"
                style="max-width:1200px"
              >
                <div
                  v-for="article in data.articles"
                  :key="article.id"
                  class="col-12 col-sm-6 col-md-4 pb-3 mx-auto d-flex"
                >
                  <div class="card">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg"
                      alt="Card image cap"
                    >
                    <div
                      class="card-body"
                      style="padding-bottom:100px;"
                    >
                      <h4 class="card-title mb-1">
                        {{ article.title }}
                      </h4>
                      <p
                        class="text-muted text-center"
                        style="text-transform: uppercase"
                      >
                        <span
                          v-for="author in article.authors"
                          :key="author.fname+author.lname"
                        >{{ author.fname }} {{ author.lname }} </span>
                      </p>
                      <p class="card-text text-justify pb-3">
                        {{ article.content }}
                      </p>
                      <div
                        class="row align-items-center justify-content-around
                          justify-content-lg-between m-0 p-0"
                        style="position:absolute; bottom:30px; width:90%"
                      >
                        <p
                          class="col-12 col-lg-auto mb-0 py-3 text-center
                            card-text"
                        >
                          21-02-2000
                        </p>
                        <div class="col-12 col-lg-auto">
                          <router-link :to="'/revista/' + article.id">
                            <mdb-btn
                              color="primary-base"
                              icon="angle-right"
                              icon-right
                              class="m-0"
                            >
                              VER MÁS
                            </mdb-btn>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 m-0 p-0 mt-3 text-right pr-3 mb-3">
                  <router-link to="/revista">
                    <span class="lead blue-grey-text">
                      VER MÁS ARTÍCULOS
                      <font-awesome-icon
                        icon="chevron-right"
                        class="ml-2"
                      />
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <!-- No result -->
          <div
            v-else
            class="no-result apollo text-center lead blue-grey-text py-5"
          >
            No hay artículos recientes
          </div>
        </template>
      </ApolloQuery>
    </div>
    <!-- Modal Agendar Cita -->
    <modalAgendarCita
      :show-modal="showAgendarCita"
      :doctor="Doctor"
      :is-loading="$apolloData.queries.Doctor.loading"
      @close="showAgendarCita = $event"
    />
    <termsCondition
      ref="temsCondition"
      :show="showTermsCondtion"
      @assigning-values="assigningValues"
    />
    <AlertaMsj
      :alerta-msj="alertaMsj"
      @cerrar="alertaMsj.content = ''"
    />
  </div>
</template>
<script>
// @ is an alias to /src
import AlertaMsj from '@/components/AlertaMsj.vue';
import {mdbAlert, mdbBtn, mdbInput} from 'mdbvue';
import TarjetaResultadoDoctor from '@/components/TarjetaResultadoDoctor';
import modalAgendarCita from '@/components/modalAgendarCita';
import gql from 'graphql-tag';
import termsCondition from '@/components/TerminosCondiciones';
import countriesGql from '../../graphql/countries.gql';
import statesGql from '../../graphql/states.gql';
import citiesGql from '../../graphql/cities.gql';
import especialidadesGql from '../../graphql/especialidades.gql';
import doctorsSearch from '../../graphql/doctorsSearch.gql';
import doctorGql from '../../graphql/doctor.gql';
import articlesGql from '../../graphql/recentArticles.gql';

export default {
  name: 'Home',
  components: {
    AlertaMsj,
    mdbAlert,
    mdbBtn,
    mdbInput,
    termsCondition,
    TarjetaResultadoDoctor,
    modalAgendarCita,
  },
  data() {
    return {
      pageSize: 10,
      // props de login.vue:
      loginBtnDisabled: false,
      alertaMsj: {
        content: '',
      },
      // Paginación de ver más medicos en directorio:
      page: 1,
      slideDirection: 'right',
      slide: -1,
      slideInt: 0,
      showMoreEnabled: true,
      showMoreSearchEnabled: false,
      showAgendarCita: false,
      newPerson: {
        gender: '',
      },
      // busqueda por nombre
      detailsDoctorId: '',
      search: {
        country: '',
        state: '',
        city: '',
        name: '',
        speciality: '',
      },
      // datos para la cita desde componente modal
      appointment: {
        date: '',
        work: '',
        workplaceSelected: '',
      },
      // query de articulos
      gqlRecentArticles: gql`
        ${articlesGql}
      `,
      // Para modal de Términos y condiciones
      checkTerm: false,
      showTermsCondtion: false,
      doctors: [],
      doctorsD: [],
      // Filtrado de doctores
      validateValue: {},
      checkFilter: false,
      filterSearchBy: false,
      showAlertSearchBy: true,
      activeProfesionalText: [],
      activePatientText: [],
      activeDirectoryText: [],
    };
  },
  computed: {
    skipDoctors() {
      if (this.search.name.length > 2
      || (this.search.speciality && this.search.country)) {
        return false;
      } else {
        return true;
      }
    },
    searchByName() {
      if (this.search.name.length < 3 && !this.search.speciality.length) {
        return 'Escribe un nombre o especialidad';
      }
      if (this.search.country.length < 2) {
        return 'Selecciona un país';
      }
      if (this.search.state.length < 2) {
        return 'Selecciona un estado';
      }
      if (this.search.city.length < 2) {
        return 'Selecciona una ciudad';
      } else {
        return 'Selecciona una especialidad';
      }
    },
  },
  watch: {
    searchByName: function() {
      this.showAlertSearchBy = false;
      setTimeout(() => {
        this.showAlertSearchBy = true;
      }, 150);
    },
    checkFilter: function(est) {
      if (!this.checkFilter) {
        this.search.country = '';
        this.search.state = '';
        this.search.city = '';
      }
    },
    slide: function() {
      setTimeout(() => {
        if (this.slideInt == 0) {
          if ((this.slide == 1 && this.slideDirection == 'right')
          || this.slide == 0) {
            this.slideChange(true);
          } else {
            this.slideChange(false);
          }
        }
      }, 5000);
    },
  },
  mounted() {
    if (this.slideInt == 0) {
      this.slideChange(true);
    }
    this.setActiveText();
  },
  methods: {
    setActiveText() {
      setTimeout(() => {
        switch (this.activeProfesionalText.length) {
          case 0:
            this.activeProfesionalText.push('médicos');
            break;
          case 1:
            this.activeProfesionalText.push('odontólogos');
            break;
          case 2:
            this.activeProfesionalText.push('psicólogos');
            break;
          case 3:
            this.activeProfesionalText.push('otros');
            break;
        }
        switch (this.activePatientText.length) {
          case 1:
            this.activePatientText.push('citas');
            break;
          case 2:
            this.activePatientText.push('tratamientos');
            break;
          case 3:
            this.activePatientText.push('recipes');
            break;
          case 4:
            this.activePatientText.push('otros');
            break;
          default:
            this.activePatientText.push('gestiona');
            break;
        }
        switch (this.activeDirectoryText.length) {
          case 1:
            this.activeDirectoryText.push('especialidad');
            break;
          case 2:
            this.activeDirectoryText.push('ubicacion');
            break;
          default:
            this.activeDirectoryText.push('nombre');
            break;
        }
        this.setActiveText();
      }, 800);
    },
    slideChange(direction) {
      this.activeProfesionalText = [];
      this.activePatientText = [];
      this.activeDirectoryText = [];
      this.slideDirection = direction ? 'right' : 'left';
      if (direction == true) {
        this.slide++;
      } else {
        this.slide--;
      }
    },
    // Para modal de Términos y condiciones
    assigningValues(value) {
      if (value != 'none') {
        this.checkTerm = value;
      }
      this.showTermsCondtion = false;
    },
    detailsDoctor(id) {
      this.detailsDoctorId = id;
      this.showAgendarCita = true;
    },
    resetPagination() {
      this.page = 1;
      this.showMoreEnabled = true;
      this.showMoreSearchEnabled = true;
    },
    showMoreSearchDoctors() {
      this.$apollo.queries.doctors.fetchMore({
        variables: {
          name: this.search.name,
          countryId: this.search.country,
          stateId: this.search.state,
          cityId: this.search.city,
          first: this.pageSize,
          offset: this.page * this.pageSize,
        },
        updateQuery: (previusResult, {fetchMoreResult}) => {
          const newDoctors = fetchMoreResult.doctorsSearch;
          const hasMore =
            fetchMoreResult.doctorsSearch.length >= this.pageSize
              ? true
              : false;
          this.showMoreSearchEnabled = hasMore;
          return {
            __typename: previusResult.doctorsSearch.__typename,
            doctorsSearch: [...previusResult.doctorsSearch, ...newDoctors],
            hasMore,
          };
        },
      });
      this.page++;
    },
  },
  apollo: {
    countries() {
      return {
        query: gql`
          ${countriesGql}
        `,
      };
    },
    states() {
      return {
        query: gql`
          ${statesGql}
        `,
        variables() {
          return {
            id: this.search.country,
          };
        },
        skip() {
          return this.search.country ? false : true;
        },
      };
    },
    cities() {
      return {
        query: gql`
          ${citiesGql}
        `,
        variables() {
          return {
            id: this.search.state,
          };
        },
        skip() {
          return this.search.state ? false : true;
        },
      };
    },
    tags() {
      return {
        query: gql`
          ${especialidadesGql}
        `,
      };
    },
    doctors() {
      return {
        query() {
          return gql`
            ${doctorsSearch}
          `;
        },
        variables() {
          return {
            name: this.search.name,
            countryId: this.search.country,
            stateId: this.search.state,
            cityId: this.search.city,
            degree: this.search.speciality,
            first: this.pageSize,
            offset: 0,
          };
        },
        skip() {
          return this.skipDoctors;
        },
        debounce: 1000,
        update: (data) => data.doctorsSearch,
      };
    },
    Doctor() {
      return {
        query: gql`
          ${doctorGql}
        `,
        variables() {
          return {
            id: this.detailsDoctorId,
          };
        },
        skip() {
          return this.detailsDoctorId ? false : true;
        },
        update: (data) => data.Doctor[0],
      };
    },
  },
};
</script>
<style lang='scss'>
//Styles del Alert
.login-bar {
  color: $primary-color;
  font-weight: 700;
  padding: 72px 0.75rem 0.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  .texto-doctor {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: doctor;
    animation-timing-function: ease;
    transform: translateX(-174px);
    white-space: nowrap;
  }
  .texto-paciente {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: paciente;
    animation-timing-function: ease;
    transform: translateX(-131px);
    position: absolute;
    white-space: nowrap;
  }
  @keyframes doctor {
    0% {
      transform: translateX(-174px);
      opacity: 0;
    }
    25% {
      transform: translateX(0px);
      opacity: 1;
    }
    45% {
      transform: translateX(0px);
      opacity: 1;
    }
    60% {
      transform: translateX(-174px);
      opacity: 0;
    }
    75% {
      transform: translateX(-174px);
    }
    100% {
      transform: translateX(-174px);
    }
  }
  @keyframes paciente {
    0% {
      transform: translateX(-131px);
    }
    25% {
      transform: translateX(-131px);
    }
    45% {
      transform: translateX(-131px);
    }
    60% {
      transform: translateX(-131px);
      opacity: 0;
    }
    75% {
      transform: translateX(0px);
      opacity: 1;
    }
    85% {
      transform: translateX(0px);
      opacity: 1;
    }
    100% {
      transform: translateX(-131px);
      opacity: 0;
    }
  }
}
.home {
  background: linear-gradient(180deg, #e5efff 0%, #e5efff00 100%);
}
[class*="text-decoration"] {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size:1.3rem;
}
.text-decoration {
  &-primary {
    color: $primary-color-dark;
    font-size: 3rem;
    line-height: 1;
    font-size:1.5rem;
    @media (min-width: $medium) {
      font-size: 1.6rem;
    }
  }
  &-secondary {
    color: $warning-color;
    @media (min-width: $medium) {
      font-size: 1.9rem;
    }
  }
}
.banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  max-width: 82rem;
  margin: auto;
  padding-bottom: .5rem;
  @media (min-width: $medium) {
    align-content: space-between;
  }
  @media (min-width: $xlarge) {
    justify-content: space-between;
  }
  .banner-header {
    font-size: 2.5rem;
    @media (min-width: $medium) {
      font-size: 3.1rem;
      margin-left: 13%
    }
    @media (min-width: $large) {
      margin-left: 8.5%
    }
  }
  .f-banner-slide {
    font-size: 1.2rem;
    span {
      transition: all .5s ease;
      opacity: 0;
    }
    &.appear span {
      opacity: 1;
    }
  }
  .transition-group {
    align-items: flex-start;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    min-height: 30rem;
    @media (min-width: $small) {
      min-height: 21rem;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: $medium) {
      min-height: 22rem;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: $large) {
      justify-content: space-between;
      min-height: 21.5rem;
    }
    @media (min-width: $xlarge) {
      justify-content: space-between;
    }
  }
  .section-directorio, .section-pacientes,
  .section-profesionales {
    margin-left: 0;
    opacity: 1;
    padding: 0;
    transition: all .8s ease;
    & > div:first-of-type {
      position: relative;
      min-height: 30rem;
      z-index: 1;
      @media (min-width: $small) {
        min-height: 21rem;
      }
      @media (min-width: $medium) {
        min-height: 22rem;
      }
      @media (min-width: $large) {
        min-height: 21.5rem;
      }
      p {
        white-space: nowrap;
        margin-right: 0;
        transition: all 1.7s ease;
      }
    }
    .banner-slide-text {
      @media (min-width: $small) {
      max-width: 22rem;
      }
    }
    &.right-enter {
      > div:first-of-type {
        p:nth-child(2) {
          margin-left: 20%;
          transition: all 2s ease;
        }
        p:nth-child(3) {
          margin-left: 25%;
          transition: all 2s ease;
        }
        p:nth-child(4) {
          margin-left: 30%;
          transition: all 2s ease;
        }
        p:nth-child(5) {
          margin-left: 35%;
          transition: all 2s ease;
        }
        p:nth-child(6) {
          margin-left: 40%;
          transition: all 2s ease;
        }
        p:nth-child(7) {
          margin-left: 45%;
          transition: all 2s ease;
        }
      }
    }
    &.left-enter {
      > div:first-of-type {
        p:nth-child(2) {
          margin-left: -20%;
          transition: all 2s ease;
        }
        p:nth-child(3) {
          margin-left: -25%;
          transition: all 2s ease;
        }
        p:nth-child(4) {
          margin-left: -30%;
          transition: all 2s ease;
        }
        p:nth-child(5) {
          margin-left: -35%;
          transition: all 2s ease;
        }
        p:nth-child(6) {
          margin-left: -40%;
          transition: all 2s ease;
        }
        p:nth-child(7) {
          margin-left: -45%;
          transition: all 2s ease;
        }
      }
    }
  }
}
.banner-bg {
  transition: all .5s ease;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  @media (min-width: $small) {
    background-size: initial;;
    background-position: center;
  }
  @media (min-width: $large) {
    background-position: right;
    background-size: cover;
  }
  &.banner-directorio {
    background-image: url('/img/amii-banner-directorio-mobile.jpg');
    @media (min-width: $small) {
      background-position: 40%;
      background-image: url('/img/amii-banner-directorio.jpg');
    }
    @media (min-width: $large) {
      background-position: center;
    }
  }
  &.banner-profesionales {
    background-image: url('/img/amii-banner-profesionales-mobile.jpg');
    @media (min-width: $small) {
      background-position: 30%;
      background-image: url('/img/amii-banner-profesionales.jpg');
    }
    @media (min-width: $large) {
      background-position: center;
    }
  }
  &.banner-pacientes {
    background-image: url('/img/amii-banner-pacientes-mobile.jpg');
    @media (min-width: $small) {
      background-image: url('/img/amii-banner-pacientes.jpg');
    }
  }
  .right-enter, .right-enter .f-banner-slide span {
    opacity: 0;
    transform: translate(30%);
  }
  .left-enter, .left-enter .f-banner-slide span {
    opacity: 0;
    transform: translate(-30%);
  }
  .right-leave, .right-leave .f-banner-slide span {
    margin-left: -30%;
    opacity: 0;
  }
  .left-leave, .left-leave .f-banner-slide span {
    margin-left: -30%;
    opacity: 0;
    &.section-directorio {
      @media (min-width: $large) {
        margin-left: 80%;
      }
    }
    &.section-profesionales {
      margin-left: 50%;
      @media (min-width: $large) {
        transform: translate(100%);
        margin-left: 35%;
      }
    }
  }
  p {
    margin: 0;
  }
}
.beneficios-bg {
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  background-image: url('/img/amii-banner-pacientes-beneficios-mobile.jpg');
  @media (min-width: $small) {
    background-image: url('/img/amii-banner-pacientes-beneficios.jpg');
  }
  @media (min-width: $medium) {
    background-size: contain;
  }
  >div {
    background-color: #e6f0ffa8;
    @media (min-width: $medium) {
      background-color: transparent;
    }
  }
}
.w-xl-75 {
  @media (min-width: $max-large) {
    max-width: 75rem;
  }
}
.corner {
  overflow: visible;
  transition: all 1s ease;
  div {
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    background-color: $success-color;
    border-radius: 2rem;
    color: white;
    display: flex;
    width: 2.7rem;
    height: 2.7rem;
    @media (min-width: $medium) {
      width: 3.7rem;
      height: 3.7rem;
    }
  }
  z-index: 1;
}
.buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  .register-btn {
    padding: .84rem .8rem !important;
    text-wrap-mode: nowrap !important;
    @media screen and (min-width: 320px) {
      padding: .84rem 2.14rem !important;
    }
  }
}
.buttons-responsive-bottom {
  position: absolute;
  bottom: .5rem;
  left: 0;
  right: 0;
  @media (min-width: $medium) {
    width: 60%;
    left: 1rem;
  }
  @media (min-width: $large) {
    width: 50%;
    bottom: 1rem;
  }
}
.rounded-xl {
  border-radius: 10em;
}
.home {
  ul {
    list-style: none;
    margin-bottom: 0;
  }

  li {
    margin-left: 1.5rem;
    position: relative;

    &::before {
      content: "\f058";
      color: $success-color;
      font-family: "Font Awesome 5 Free";
      font-style: normal;
      font-weight: 900;
      left: -20px;
      position: absolute;
    }
    &:not(li:last-of-type) {
      margin-bottom: .5rem;
    }
  }

}
.img-beneficios {
  @media (min-width: $medium) {
    flex: 0 0 50%;
    max-width: 22rem;
  }
  img {
    width: 100%;
  }
}
#reserva-tu-cita {
  overflow: hidden;
  form {
    transition: all 1s;
  }
}
.h-reduced {
  height: 168px;
  overflow-y: auto;
  padding-top: 1px;
}
.h-650px {
  height: 720px;
  @media screen and (min-width: $small) and (max-width: $large) {
    height: 590px !important;
  }
  @media screen and (min-width: $large) {
    height: 500px !important;
  }
}
#formLogin {
  position: absolute;
  top: 0;
  transform: translate(130%);
  width: 90%;
}
.title-citas {
  text-align: center;
}
#firstButtonHome {
  @media screen and (min-width: $xlarge) {
    font-size: 1.3rem !important;
  }
}
.gap-2 {
  row-gap: 1rem;
}
</style>
